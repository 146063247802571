const styles = theme => ({
  root: {
    width: '100%',
  },
  padAuto: {
    padding: '10px 100px 10px 100px',
    [theme.breakpoints.down('lg')]: {
      padding: '5px 50px 5px 50px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  },
  pdTop100: {
    paddingTop: 60,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20
    }
  },
  menuHidden: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  center: {
    textAlign: 'center'
  },
  font30: {
    fontSize: 30,
    fontWeight: 'bold',
    [theme.breakpoints.down(500)]: {
      fontSize: 30
    }
  },
  btnAction: {
    maxHeight: 36,
    textTransform: 'none',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    borderRadius: 4,
    backgroundColor: '#FF3D00',
    '&:hover': {
      backgroundColor: '#7ABE2A'
    },
    boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)'
  },
  menuDrawer: {
    lineHeight: '50px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 16,
    textTransform: 'none',
    textDecoration: 'none',
    marginLeft: 14,
    '&:hover': {
      textDecoration: 'none',
    },
    width: 120,
  },
});

export default styles;
