import { Button, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { Component, lazy, Suspense } from "react";
import { isIOS, isMobile } from "react-device-detect";
import line from "./images/line.jpg";
import styles from "./styles";
import { Switch, Route, withRouter } from "react-router-dom";
import { compose } from "redux";

const MenuPopup = lazy(() => import("./components/menu-popup/MenuPopup"));
const CriteriaItem = lazy(() =>
  import("./components/criteria-item/CriteriaItem")
);
const RegisterForm = lazy(() =>
  import("./components/register-form/RegisterForm")
);
const Pricing = lazy(() => import("./components/pricing/Pricing"));
const Feature = lazy(() => import("./components/feature/Feature"));
const MainLandingPage = lazy(() => import("./components/home/MainLandingPage"));
const Footer = lazy(() => import("./components/footer/Footer"));
const Header = lazy(() => import("./components/header/Header"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPop: false,
    };
  }

  onOpenPop = () => {
    const { openPop } = this.state;
    this.setState({ openPop: !openPop });
  };

  render() {
    const { classes } = this.props;
    const { openPop } = this.state;

    return (
      <div className={classes.root}>
        {/* HEADER */}
        <Suspense fallback={""}>
          <Header />
        </Suspense>
        {/* RENDER PART */}
        <Suspense fallback={""}>
          <Switch>
            <Route path="/" exact component={MainLandingPage} />
            <Route path="/gia" component={Pricing} />
            <Route path="/tinh-nang" component={Feature} />
          </Switch>
        </Suspense>
        <Grid
          item
          md={12}
          className={classNames(classes.center, classes.pdTop100)}
        >
          <img src={line} alt="line" style={{ width: "90%" }} />
        </Grid>

        {/* selection 6 */}
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ position: "relative" }}
          id="act"
        >
          <Grid item md={12} xs={12}>
            <Typography
              component="p"
              className={classNames(
                classes.pdTop100,
                classes.center,
                classes.font30
              )}
            >
              Đã đến lúc số hóa quản lý doanh nghiệp.
              <br />
              Tiết kiệm thời gian, tăng hiệu quả công tác hành chính nhân sự lên
              200%.
              <br />
              <br />
              {isMobile && (
                <React.Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classNames(classes.btnAction)}
                  >
                    <a
                      href={
                        isIOS
                          ? "https://apps.apple.com/vn/app/wisami-go-ch%E1%BA%A5m-c%C3%B4ng-t%E1%BB%B1-%C4%91%E1%BB%99ng/id1448098724?l=vi&fbclid=IwAR3s_lVfgOqDEh6ezDCv_KwKX6cT0y8b53cX-zEckS2C4p-hRhlaeV8SaJU"
                          : "market://details?id=com.wisami2"
                      }
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Tải ngay ứng dụng WISAMI GO
                    </a>
                  </Button>
                  <br />
                  <span className={classes.menuDrawer}>15 ngày miễn phí</span>
                </React.Fragment>
              )}
            </Typography>
          </Grid>

          <Grid
            item
            md={12}
            style={{
              backgroundColor: "#3795DB",
              margin: "200px 0px 200px 0px",
            }}
          >
            <Grid
              item
              md={7}
              className={classNames(classes.padAuto, classes.menuHidden)}
            >
              <Grid container alignItems="center" style={{ height: 255 }}>
                <Suspense fallback={""}>
                  <CriteriaItem
                    icon="lock"
                    line1="Tuyệt đối bảo mật"
                    line2="thông tin khách hàng"
                  />
                  <CriteriaItem
                    icon="pan_tool"
                    line1="Chống chấm công"
                    line2="gian lận, chấm công hộ"
                  />
                  <CriteriaItem
                    icon="mood"
                    line1="Chỉ thu phí khi"
                    line2="khách hàng đã hài lòng"
                  />
                </Suspense>
              </Grid>
            </Grid>
          </Grid>
          {/* FORM REGISTER */}
          <Suspense fallback={""}>
            <RegisterForm />
          </Suspense>
        </Grid>

        {/* FOOTER */}
        <Suspense fallback={""}>
          <Footer />
        </Suspense>
        {/* pop up */}
        <Suspense fallback={""}>
          <MenuPopup isOpenPopup={openPop} onOpenPop={this.onOpenPop} />
        </Suspense>
      </div>
    );
  }
}

export default compose(withRouter, withStyles(styles))(App);
